import React from 'react';
import ReactDOM from 'react-dom';

import Wizard from 'src/private_challenges/Wizard';
import UpdatePrivateChallenge from 'src/private_challenges/UpdatePrivateChallenge';

document.addEventListener('DOMContentLoaded', () => {
  const wizards = document.querySelectorAll('[data-react="Wizard"]');
  [...wizards].forEach((wizard) => {
    const props = JSON.parse(wizard.getAttribute('data-props'));
    ReactDOM.render(<Wizard {...props} />, wizard);
  });

  const updatePrivateChallenges = document.querySelectorAll('[data-react="UpdatePrivateChallenge"]');
  [...updatePrivateChallenges].forEach((updatePrivateChallenge) => {
    const props = JSON.parse(updatePrivateChallenge.getAttribute('data-props'));
    ReactDOM.render(<UpdatePrivateChallenge {...props} />, updatePrivateChallenge);
  });
});
